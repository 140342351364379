import {
  ACCOUNT_LOGIN,
  ACCOUNT_LOGOUT,
  ACCOUNT_DELETE_STARTED,
  ACCOUNT_DELETE_FAILED,
  ACCOUNT_DELETE_SUCCESS,
  ACCOUNT_UPDATE_STARTED,
  ACCOUNT_UPDATE_FAILED,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_VALIDATION_STARTED,
  ACCOUNT_VALIDATION_FAILED,
  ACCOUNT_VALIDATION_SUCCESS,
} from '../constants';

const initialState = {
  user: null,
  authToken: null,
  loggedIn: false,
  error: null,
  isLoading: false,
};

export default function reducer(state = initialState, action = { type: null }) {
  switch (action.type) {
    case ACCOUNT_UPDATE_SUCCESS:
    case ACCOUNT_VALIDATION_SUCCESS:
    case ACCOUNT_LOGIN:
      return {
        ...state,
        user: action.payload.user,
        authToken: action.payload.authToken,
        loggedIn: true,
      };
    case ACCOUNT_LOGOUT:
    case ACCOUNT_DELETE_SUCCESS:
    case ACCOUNT_VALIDATION_FAILED:
      return {
        ...initialState,
      };
    case ACCOUNT_UPDATE_STARTED:
    case ACCOUNT_DELETE_STARTED:
    case ACCOUNT_VALIDATION_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case ACCOUNT_UPDATE_FAILED:
    case ACCOUNT_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
