import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import withApollo from 'next-with-apollo'
import { RetryLink } from '@apollo/client/link/retry'
import fetch from 'isomorphic-unfetch'
import { LIVE_SITE_CMS } from '@constants'
import hash from 'object-hash'
import { print } from "graphql/language/printer";

let WORDPRESS_URL = process.env.NEXT_PUBLIC_WORDPRESS_URL || `${LIVE_SITE_CMS}/`
if (
  process.env.NODE_ENV === 'development' &&
  !process.env.NEXT_PUBLIC_WORDPRESS_URL
) {
  // WORDPRESS_URL = 'http://localhost/select/select-model-cms/'
  // WORDPRESS_URL = 'http://127.0.0.1/select-model-cms/'
}

const authLink = setContext((req, { headers, authToken }) => {
  if (req.variables.nonce)
    return {
      fetchOptions: {
        credentials: 'include',
      },
      headers: {
        ...headers,
        'X-WP-Nonce': req.variables.nonce,
      },
    }

  if (authToken)
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${authToken}`,
      },
    }
})

const createLink = (curSite, sites) => {
  const curSiteName = typeof curSite === 'string' ? curSite : curSite.site
  const curSiteUrl =
    typeof curSite === 'string' ? `${curSite}/graphql/` : curSite.uri

  const curLink = new createHttpLink({
    fetch, // Switches between unfetch & node-fetch for client & server.
    uri: `${WORDPRESS_URL}${curSiteUrl}`,
  })
  if (sites.length === 0) return curLink

  return ApolloLink.split(
    (operation) => {
      const opSite = operation.getContext().site
      if (curSiteName === opSite) return true
      if (
        curSiteName === 'WP_ROOT' &&
        (opSite === undefined || opSite === null)
      )
        return true
      return false
    },
    curLink,
    createLink(sites.shift(), [...sites])
  )
}

const buildLinkChain = () => {
  // WP_ROOT is last so it will be used as the default/fallback
  //
  // The default graphql endpoint is considered to be a WordPress setup
  // We take the name used here and append it to the WORDPRESS_URL,
  // then we prepend 'graphql/', therefore 'london' would end up as
  // `${WORDPRESS_URL}/london/graphql/`
  const sites = [
    'london',
    'milano',
    'paris',
    'stockholm',
    'atlanta',
    'chicago',
    'los-angeles',
    'miami',
    'modellink',
    {
      site: 'solarnet',
      uri: `api/graphql.php`,
    },
    {
      site: 'WP_ROOT',
      uri: `graphql/`,
    },
  ]

  return createLink(sites.shift(), sites)
}

const retryLink = new RetryLink({
  delay: {
    initial: 1000 * 5,
    max: 1000 * 40,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, operation) => {
      const doNotRetryCodes = [500, 400]
      if (error && error.statusCode !== 200) {
        console.log(
          'retryIf called and non-200 status returned',
          error && error.statusCode
        )
      }
      return !!error
    },
  },
})

const cacheIdLink = new ApolloLink((operation, forward) => {
  if (!process.env.NEXT_PUBLIC_ENABLE_HTTP_CACHE_ID) {
    return forward(operation);
  }
  const {query, variables, operationName } = operation;
  const site = operation.getContext().site;

  if (site === 'solarnet') {
    return forward(operation);
  }

  const cacheObject = {
    operationName,
    variables,
    query: print(query),
    site: operation.getContext().site
  }
  const cacheHash = hash(cacheObject);
  operation.setContext(({headers}) => ({ headers: {
    'X-Client-Cache-Id': cacheHash,
    ...headers
    }}))
  return forward(operation);
})

export const apolloClient = ({ initialState, ctx } = {}) => {
  const links = [cacheIdLink, retryLink, authLink, buildLinkChain()]
  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link: ApolloLink.from(links),
    // link: authLink.concat(buildLinkChain()).concat(retryLink),
    cache: new InMemoryCache().restore(initialState || {}),
  })
}

// Export a HOC from next-with-apollo
// Docs: https://www.npmjs.com/package/next-with-apollo
export default withApollo(apolloClient)
