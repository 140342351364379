import { ApolloProvider } from "@apollo/client";
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import '../styles/base.css'

import getGlobalSettings from 'helpers/getGlobalSettings'
import getSites from 'helpers/getSites'
import GlobalContextProvider from 'context/GlobalContextProvider'
import SitesContextProvider from 'context/SitesContextProvider'
import withApollo from 'lib/withApollo'
import SEOSelect from 'next-seo-select.config'
import SEOModellink from 'next-seo-modellink.config'

import isModellinkSite from '../helpers/isModellinkSite';

function App({ Component, pageProps, apollo }) {
  const router = useRouter()

  const SEO = isModellinkSite() ? SEOModellink : SEOSelect;

  return (
    <ApolloProvider client={apollo}>
      <DefaultSeo {...SEO} />
      <GlobalContextProvider>
        <SitesContextProvider
          siteSlug={router.query.site}
          sites={pageProps.sites}
        >
          <Component {...pageProps} globalSettings={pageProps.globalSettings} />
        </SitesContextProvider>
      </GlobalContextProvider>
    </ApolloProvider>
  )
}

App.getInitialProps = async ({ ctx }) => {
  return {
    pageProps: {
      globalSettings: await getGlobalSettings(ctx.query.site),
      sites: await getSites(),
    },
  }
}

export default withApollo(App)
