import {
  SITES_FETCH_STARTED,
  SITES_FETCH_FAILED,
  SITES_FETCH_SUCCESS,
  SITES_SELECTED,
} from '../constants';

const initialState = {
  items: null,
  error: null,
  isLoading: false,
  active: null,
};

export default function reducer(state = initialState, action = { type: null }) {
  switch (action.type) {
    case SITES_FETCH_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case SITES_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case SITES_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload.items,
      };
    case SITES_SELECTED:
      return {
        ...state,
        active: action.payload.id,
      };
    default:
      return state;
  }
}
