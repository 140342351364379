import {
  COLLECTIONS_CREATE_TEMPORARY,
  COLLECTIONS_DELETE_TEMPORARY,
  COLLECTIONS_LOADED,
  COLLECTIONS_CREATE_STARTED,
  COLLECTIONS_CREATE_FAILED,
  COLLECTIONS_CREATE_SUCCESS,
} from '../constants'

const initialState = {
  temporary: null,
  loaded: false,
  items: null,
  isLoading: false,
  error: null,
};

export default function reducer(state = initialState, action = { type: null }) {
  switch (action.type) {
    case COLLECTIONS_CREATE_TEMPORARY:
      return {
        ...state,
        temporary: {
          name: action.payload.name,
          items: null,
        },
      }
    case COLLECTIONS_DELETE_TEMPORARY:
      return {
        ...state,
        temporary: null,
      }
    case COLLECTIONS_LOADED:
      return {
        ...state,
        temporary: { ...action.payload },
        loaded: true,
      };
    case COLLECTIONS_CREATE_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case COLLECTIONS_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case COLLECTIONS_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        items: [
          ...state.items,
          action.payload.collection,
        ]
      };
    default:
      return state
  }
}
